@font-face {
  font-family: 'TannerIconSet';
  src: url("../fonts/TannerIconSet.eot");
  src: url("../fonts/TannerIconSet.eot#iefix") format("embedded-opentype"), url("../fonts/TannerIconSet.woff2") format("woff2"), url("../fonts/TannerIconSet.ttf") format("truetype"), url("../fonts/TannerIconSet.woff") format("woff"), url("../fonts/TannerIconSet.svg#TannerIconSet") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block; }

[class^="ticon-"], [class*=" ticon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'TannerIconSet' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.ticon-user:before {
  content: "\e9c9"; }

.ticon-admin:before {
  content: "\e9ca"; }

.ticon-avatar:before {
  content: "\e9cb"; }

.ticon-collaborate:before {
  content: "\e9cc"; }

.ticon-activated-filled:before {
  content: "\e9cd"; }

.ticon-activated:before {
  content: "\e9cf"; }

.ticon-face-add:before {
  content: "\e9d0"; }

.ticon-dissatisfied-filled:before {
  content: "\e9d1"; }

.ticon-dissatisfied:before {
  content: "\e9d3"; }

.ticon-dizzy-filled:before {
  content: "\e9d4"; }

.ticon-dizzy:before {
  content: "\e9d6"; }

.ticon-neutral-filled:before {
  content: "\e9d7"; }

.ticon-neutral:before {
  content: "\e9d9"; }

.ticon-pending-filled:before {
  content: "\e9da"; }

.ticon-pending:before {
  content: "\e9dc"; }

.ticon-satisfied-filled:before {
  content: "\e9dd"; }

.ticon-satisfied:before {
  content: "\e9df"; }

.ticon-wink-filled:before {
  content: "\e9e0"; }

.ticon-wink:before {
  content: "\e9e2"; }

.ticon-follow:before {
  content: "\e9e3"; }

.ticon-group:before {
  content: "\e9e4"; }

.ticon-online:before {
  content: "\e9e5"; }

.ticon-partnership:before {
  content: "\e9e6"; }

.ticon-person:before {
  content: "\e9e7"; }

.ticon-role:before {
  content: "\e9e8"; }

.ticon-checkbox-undeterminate-filled:before {
  content: "\e9e9"; }

.ticon-asleep:before {
  content: "\e9ea"; }

.ticon-checkbox-checked-filled:before {
  content: "\e9eb"; }

.ticon-checkbox-checked:before {
  content: "\e9ed"; }

.ticon-checkbox-unchecked:before {
  content: "\e9ee"; }

.ticon-checkbox-undeterminate:before {
  content: "\e9ef"; }

.ticon-column:before {
  content: "\e9f0"; }

.ticon-favorite-filled:before {
  content: "\e9f1"; }

.ticon-favorite:before {
  content: "\e9f2"; }

.ticon-grid:before {
  content: "\e9f3"; }

.ticon-light:before {
  content: "\e9f4"; }

.ticon-list:before {
  content: "\e9f5"; }

.ticon-locked:before {
  content: "\e9f6"; }

.ticon-microphone-off:before {
  content: "\e9f7"; }

.ticon-microphone:before {
  content: "\e9f8"; }

.ticon-notification-off:before {
  content: "\e9f9"; }

.ticon-notification:before {
  content: "\e9fa"; }

.ticon-phone-off:before {
  content: "\e9fb"; }

.ticon-phone:before {
  content: "\e9fc"; }

.ticon-radio-button-checked:before {
  content: "\e9fd"; }

.ticon-radio-button-unchecked:before {
  content: "\e9fe"; }

.ticon-row:before {
  content: "\e9ff"; }

.ticon-star-filled:before {
  content: "\ea00"; }

.ticon-star-half:before {
  content: "\ea01"; }

.ticon-star:before {
  content: "\ea02"; }

.ticon-unlocked:before {
  content: "\ea03"; }

.ticon-mode-1:before {
  content: "\ea04"; }

.ticon-mode-2:before {
  content: "\ea05"; }

.ticon-loading:before {
  content: "\ea06"; }

.ticon-checkmark-filled:before {
  content: "\ea07"; }

.ticon-checkmark:before {
  content: "\ea09"; }

.ticon-error-filled:before {
  content: "\ea0a"; }

.ticon-error:before {
  content: "\ea0c"; }

.ticon-help-filled:before {
  content: "\ea0d"; }

.ticon-help:before {
  content: "\ea0f"; }

.ticon-info-filled:before {
  content: "\ea10"; }

.ticon-info:before {
  content: "\ea12"; }

.ticon-misuse-filled:before {
  content: "\ea13"; }

.ticon-misuse:before {
  content: "\e96b"; }

.ticon-warning-alt-filled:before {
  content: "\ea15"; }

.ticon-warning-alt:before {
  content: "\ea17"; }

.ticon-warning-filled:before {
  content: "\ea18"; }

.ticon-warning:before {
  content: "\ea1a"; }

.ticon-delicious:before {
  content: "\ea1b"; }

.ticon-digg:before {
  content: "\ea1c"; }

.ticon-facebook:before {
  content: "\ea1d"; }

.ticon-flickr:before {
  content: "\ea1e"; }

.ticon-github:before {
  content: "\ea1f"; }

.ticon-linkedin:before {
  content: "\ea20"; }

.ticon-livestream:before {
  content: "\ea21"; }

.ticon-pinterest:before {
  content: "\ea22"; }

.ticon-quora:before {
  content: "\ea23"; }

.ticon-skype:before {
  content: "\ea24"; }

.ticon-slack:before {
  content: "\ea25"; }

.ticon-snapchat:before {
  content: "\ea26"; }

.ticon-stumbleupon:before {
  content: "\ea27"; }

.ticon-tumblr:before {
  content: "\ea28"; }

.ticon-twitter:before {
  content: "\ea29"; }

.ticon-wykop:before {
  content: "\ea2a"; }

.ticon-xing:before {
  content: "\ea2b"; }

.ticon-yahoo:before {
  content: "\ea2c"; }

.ticon-youtube:before {
  content: "\ea2d"; }

.ticon-add:before {
  content: "\ea2e"; }

.ticon-app-switcher:before {
  content: "\ea2f"; }

.ticon-arrow-down-left:before {
  content: "\ea30"; }

.ticon-arrow-down-right:before {
  content: "\ea31"; }

.ticon-arrow-down:before {
  content: "\ea32"; }

.ticon-arrow-left:before {
  content: "\ea33"; }

.ticon-arrow-right:before {
  content: "\ea34"; }

.ticon-arrow-up-left:before {
  content: "\ea35"; }

.ticon-arrow-up-right:before {
  content: "\ea36"; }

.ticon-arrow-up:before {
  content: "\ea37"; }

.ticon-caret-down:before {
  content: "\ea38"; }

.ticon-caret-left:before {
  content: "\ea39"; }

.ticon-caret-right:before {
  content: "\ea3a"; }

.ticon-caret-sort:before {
  content: "\ea3b"; }

.ticon-caret-up:before {
  content: "\ea3c"; }

.ticon-chevron-down:before {
  content: "\ea3d"; }

.ticon-chevron-left:before {
  content: "\ea3e"; }

.ticon-chevron-right:before {
  content: "\ea3f"; }

.ticon-chevron-up:before {
  content: "\ea40"; }

.ticon-chrevron-sort:before {
  content: "\ea41"; }

.ticon-close:before {
  content: "\ea42"; }

.ticon-down-to-bottom:before {
  content: "\ea43"; }

.ticon-draggable:before {
  content: "\ea44"; }

.ticon-ellipses:before {
  content: "\ea45"; }

.ticon-first-page:before {
  content: "\ea46"; }

.ticon-last-page:before {
  content: "\ea47"; }

.ticon-menu:before {
  content: "\ea48"; }

.ticon-overflow-menu:before {
  content: "\ea49"; }

.ticon-subtract:before {
  content: "\ea4a"; }

.ticon-up-to-top:before {
  content: "\ea4b"; }

.ticon-zoom-in:before {
  content: "\ea4c"; }

.ticon-zoom-out:before {
  content: "\ea4d"; }

.ticon-accessibility:before {
  content: "\e91d"; }

.ticon-activity:before {
  content: "\e91e"; }

.ticon-arrival:before {
  content: "\e91f"; }

.ticon-at:before {
  content: "\e920"; }

.ticon-bee:before {
  content: "\e921"; }

.ticon-blog:before {
  content: "\e922"; }

.ticon-bluetooth:before {
  content: "\e923"; }

.ticon-bolt:before {
  content: "\e924"; }

.ticon-bookmark:before {
  content: "\e925"; }

.ticon-calculator:before {
  content: "\e926"; }

.ticon-calendar:before {
  content: "\e927"; }

.ticon-camera-action:before {
  content: "\e928"; }

.ticon-camera:before {
  content: "\e929"; }

.ticon-car:before {
  content: "\e92a"; }

.ticon-catalog:before {
  content: "\e92b"; }

.ticon-chat:before {
  content: "\e92c"; }

.ticon-chip:before {
  content: "\e92d"; }

.ticon-cloud:before {
  content: "\e92e"; }

.ticon-cloud-download:before {
  content: "\e92f"; }

.ticon-lightning:before {
  content: "\e930"; }

.ticon-rain:before {
  content: "\e931"; }

.ticon-snow:before {
  content: "\e932"; }

.ticon-upload:before {
  content: "\e933"; }

.ticon-code:before {
  content: "\e934"; }

.ticon-compass:before {
  content: "\e935"; }

.ticon-debug:before {
  content: "\e936"; }

.ticon-delivery-truck:before {
  content: "\e937"; }

.ticon-departure:before {
  content: "\e938"; }

.ticon-desktop:before {
  content: "\e939"; }

.ticon-email:before {
  content: "\e93a"; }

.ticon-event:before {
  content: "\e93b"; }

.ticon-filter:before {
  content: "\e93c"; }

.ticon-fingerprint-recognition:before {
  content: "\e93d"; }

.ticon-forum:before {
  content: "\e93e"; }

.ticon-globe:before {
  content: "\e93f"; }

.ticon-home:before {
  content: "\e940"; }

.ticon-hotel:before {
  content: "\e941"; }

.ticon-idea:before {
  content: "\e942"; }

.ticon-industry:before {
  content: "\e943"; }

.ticon-location:before {
  content: "\e944"; }

.ticon-map:before {
  content: "\e945"; }

.ticon-mobile-add:before {
  content: "\e946"; }

.ticon-check:before {
  content: "\e947"; }

.ticon-mobile-download:before {
  content: "\e948"; }

.ticon-mobile:before {
  content: "\e949"; }

.ticon-no-activity:before {
  content: "\e94a"; }

.ticon-password:before {
  content: "\e94b"; }

.ticon-plane:before {
  content: "\e94c"; }

.ticon-printer:before {
  content: "\e94d"; }

.ticon-purchase:before {
  content: "\e94e"; }

.ticon-recommend:before {
  content: "\e94f"; }

.ticon-request-quote:before {
  content: "\e950"; }

.ticon-restaurant:before {
  content: "\e951"; }

.ticon-rocket:before {
  content: "\e952"; }

.ticon-rss:before {
  content: "\e953"; }

.ticon-schedule:before {
  content: "\e954"; }

.ticon-search:before {
  content: "\e955"; }

.ticon-settings:before {
  content: "\e956"; }

.ticon-share:before {
  content: "\e957"; }

.ticon-bag:before {
  content: "\e958"; }

.ticon-cart:before {
  content: "\e959"; }

.ticon-shuttle:before {
  content: "\e95a"; }

.ticon-tag:before {
  content: "\e95b"; }

.ticon-task:before {
  content: "\e95c"; }

.ticon-taxi:before {
  content: "\e95d"; }

.ticon-temperature:before {
  content: "\e95e"; }

.ticon-terminal:before {
  content: "\e95f"; }

.ticon-thumbsup:before {
  content: "\e960"; }

.ticon-ticket:before {
  content: "\e961"; }

.ticon-time:before {
  content: "\e962"; }

.ticon-tool:before {
  content: "\e963"; }

.ticon-translate:before {
  content: "\e964"; }

.ticon-van:before {
  content: "\e965"; }

.ticon-view:before {
  content: "\e966"; }

.ticon-vpn:before {
  content: "\e967"; }

.ticon-watson:before {
  content: "\e968"; }

.ticon-wikis:before {
  content: "\e969"; }

.ticon-feedback:before {
  content: "\e96c"; }

.ticon-exit:before {
  content: "\e9ce"; }

.ticon-exchange:before {
  content: "\ea4e"; }

.ticon-arrow-cursor:before {
  content: "\e96a"; }

.ticon-hand-cursor:before {
  content: "\e91c"; }

.ticon-align-center:before {
  content: "\e96d"; }

.ticon-align-left:before {
  content: "\e96e"; }

.ticon-align-right:before {
  content: "\e96f"; }

.ticon-attachment:before {
  content: "\e970"; }

.ticon-bold:before {
  content: "\e971"; }

.ticon-color:before {
  content: "\e972"; }

.ticon-creation:before {
  content: "\e973"; }

.ticon-crop:before {
  content: "\e974"; }

.ticon-cut:before {
  content: "\e975"; }

.ticon-delete:before {
  content: "\e976"; }

.ticon-edit:before {
  content: "\e977"; }

.ticon-fill:before {
  content: "\e978"; }

.ticon-highlight:before {
  content: "\e979"; }

.ticon-image:before {
  content: "\e97a"; }

.ticon-indent-less:before {
  content: "\e97b"; }

.ticon-indent-more:before {
  content: "\e97c"; }

.ticon-indent:before {
  content: "\e97d"; }

.ticon-italic:before {
  content: "\e97e"; }

.ticon-link:before {
  content: "\e97f"; }

.ticon-save:before {
  content: "\e980"; }

.ticon-scale:before {
  content: "\e981"; }

.ticon-selection:before {
  content: "\e982"; }

.ticon-spell-check:before {
  content: "\e983"; }

.ticon-underline:before {
  content: "\e984"; }

.ticon-api:before {
  content: "\e985"; }

.ticon-application:before {
  content: "\e986"; }

.ticon-copy:before {
  content: "\e987"; }

.ticon-document-add:before {
  content: "\e988"; }

.ticon-document:before {
  content: "\e989"; }

.ticon-paste:before {
  content: "\e98a"; }

.ticon-subtract1:before {
  content: "\e98b"; }

.ticon-tasks:before {
  content: "\e98c"; }

.ticon-file-download:before {
  content: "\e98d"; }

.ticon-dvr:before {
  content: "\e98e"; }

.ticon-export:before {
  content: "\e98f"; }

.ticon-folder-add:before {
  content: "\e990"; }

.ticon-folder:before {
  content: "\e991"; }

.ticon-gif:before {
  content: "\e992"; }

.ticon-hd-filled:before {
  content: "\e993"; }

.ticon-hd:before {
  content: "\e994"; }

.ticon-hdr:before {
  content: "\e995"; }

.ticon-list-bullet:before {
  content: "\e996"; }

.ticon-list-check:before {
  content: "\e997"; }

.ticon-list-numbered:before {
  content: "\e998"; }

.ticon-panel-expansion:before {
  content: "\e999"; }

.ticon-pdf:before {
  content: "\e99a"; }

.ticon-popup:before {
  content: "\e99b"; }

.ticon-scale1:before {
  content: "\e99c"; }

.ticon-table-of-contents:before {
  content: "\e99d"; }

.ticon-template:before {
  content: "\e99e"; }

.ticon-tif:before {
  content: "\e99f"; }

.ticon-upload1:before {
  content: "\e9a0"; }

.ticon-workspace:before {
  content: "\e9a1"; }

.ticon-unstructured:before {
  content: "\e9a2"; }

.ticon-analytics:before {
  content: "\e9a3"; }

.ticon-archive:before {
  content: "\e9a4"; }

.ticon-base:before {
  content: "\e9a5"; }

.ticon-bar:before {
  content: "\e9a6"; }

.ticon-bubble:before {
  content: "\e9a7"; }

.ticon-line:before {
  content: "\e9a8"; }

.ticon-network:before {
  content: "\e9a9"; }

.ticon-venn-diagram:before {
  content: "\e9aa"; }

.ticon-check1:before {
  content: "\e9ab"; }

.ticon-data-1:before {
  content: "\e9ac"; }

.ticon-data-2:before {
  content: "\e9ad"; }

.ticon-diagram:before {
  content: "\e9ae"; }

.ticon-data-error:before {
  content: "\e9af"; }

.ticon-flow:before {
  content: "\e9b0"; }

.ticon-parent-child:before {
  content: "\e9b1"; }

.ticon-presentation:before {
  content: "\e9b2"; }

.ticon-report:before {
  content: "\e9b3"; }

.ticon-set:before {
  content: "\e9b4"; }

.ticon-structured:before {
  content: "\e9b5"; }

.ticon-table-1:before {
  content: "\e9b6"; }

.ticon-table-2:before {
  content: "\e9b7"; }

.ticon-back:before {
  content: "\e9b8"; }

.ticon-forward:before {
  content: "\e9b9"; }

.ticon-pause-button:before {
  content: "\e9ba"; }

.ticon-pause:before {
  content: "\e9bb"; }

.ticon-power:before {
  content: "\e9bc"; }

.ticon-record:before {
  content: "\e9bd"; }

.ticon-start-button:before {
  content: "\e9be"; }

.ticon-start:before {
  content: "\e9bf"; }

.ticon-stop-button:before {
  content: "\e9c0"; }

.ticon-stop:before {
  content: "\e9c1"; }

.ticon-video-add:before {
  content: "\e9c2"; }

.ticon-video-chat:before {
  content: "\e9c3"; }

.ticon-video-off:before {
  content: "\e9c4"; }

.ticon-video-on:before {
  content: "\e9c5"; }

.ticon-volume-down:before {
  content: "\e9c6"; }

.ticon-volume-mute:before {
  content: "\e9c7"; }

.ticon-volume-up:before {
  content: "\e9c8"; }

.ticon-a-z:before {
  content: "\e900"; }

.ticon-arrows-horizontal:before {
  content: "\e901"; }

.ticon-arrows-vertical:before {
  content: "\e902"; }

.ticon-command:before {
  content: "\e903"; }

.ticon-connect:before {
  content: "\e904"; }

.ticon-fade:before {
  content: "\e905"; }

.ticon-insert:before {
  content: "\e906"; }

.ticon-launch:before {
  content: "\e907"; }

.ticon-major-to-minor:before {
  content: "\e908"; }

.ticon-maximize:before {
  content: "\e909"; }

.ticon-migrate:before {
  content: "\e90a"; }

.ticon-minimize:before {
  content: "\e90b"; }

.ticon-minor-to-major:before {
  content: "\e90c"; }

.ticon-move:before {
  content: "\e90d"; }

.ticon-new-tab:before {
  content: "\e90e"; }

.ticon-option:before {
  content: "\e90f"; }

.ticon-pin:before {
  content: "\e910"; }

.ticon-renew:before {
  content: "\e911"; }

.ticon-repeat:before {
  content: "\e912"; }

.ticon-reply:before {
  content: "\e913"; }

.ticon-reset:before {
  content: "\e914"; }

.ticon-restart:before {
  content: "\e915"; }

.ticon-rotate:before {
  content: "\e916"; }

.ticon-run:before {
  content: "\e917"; }

.ticon-scan:before {
  content: "\e918"; }

.ticon-shift:before {
  content: "\e919"; }

.ticon-split-screen:before {
  content: "\e91a"; }

.ticon-z-a:before {
  content: "\e91b"; }

.icon-drawer.active{
    color: #0080FF !important;
  }

  .icon-drawer {
    text-decoration: none !important;
    font-size: 24px !important;
    color: #FFF !important;

  }

