@import "assets/styles/tanner-icon-font.css";

* {
  box-sizing: border-box;
}

body {
  background: linear-gradient(180deg,#305cb9 0%, #305cb9 240px, rgba(247,247,247,1) 240px, rgba(247,247,247,1) 1500px) !important;
  background-color: #f7f7f7;
  background-repeat: no-repeat;
}

h1 {
  font-size: 1.5rem !important;
}

h2{
  font-size: 1.3rem !important;
}

a {
  text-decoration: none !important;
}

.utilities-appbar {
  z-index: 9999999;
}

.ticon-loading {
  animation: spin 1s linear infinite !important;
  font-size: 18px;
  margin-right: 3px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@media only screen and (max-width: 768px){

  .appBar {
    margin: 0 !important;
    width: 100% !important;
  }

  .fullContainer {
    padding: 0 22px;
  }
}

@media only screen and (min-width : 1024px) and (max-width : 1224px) {
  .appBar {
    margin: 0 !important;
    width: 100% !important;
  }

  .fullContainer {
    padding: 0 calc(22px);
  }
}


// todo: el responsive del framework esta en candela, preguntar a coni (UX) que quieren
// todo: (en el ultimo release si se le puede poner la clase) el drawer item separator no permite ponerle una clase ni un color, solo un label
// todo: ver los colores del framework con los que da la UX porque no son los mismos
// todo: agregar a los iconos el color
// todo: el boton disabled sigue teniendo cursor pointer en el framework
// todo: textfield con icono, principalmente para los inputs de busqueda
// todo: los iconos de los alert están todos iguales
// todo: clip-rule and clip-path lanzan error por el guion en los iconos
// todo: el boton no se le puede mandar el type="button"
// todo: en el pagination el color del activo es azure y no cerulean blue
// todo: modal es super poco customizable, principalmente el title


// quitar del framework en el switch
// padding-left: 35px;
//	margin-bottom: 12px;
